import api from '@/base/utils/request';

export const fetchLiveList = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountLive/index',
    method: 'post',
    data
  })
}

export const getAllCategories = () => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/all',
    method: 'post',
  })
}

export const setLive = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountLive/save',
    method: 'post',
    data
  })
}

export const updateLiveSort = (id, sort) => {
  return api({
    url: '/admin/wx_open/WxAccountLive/updateSort',
    method: 'post',
    data: {
      id,
      sort
    }
  })
}

export const removeLive = (id) => {
  return api({
    url: '/admin/wx_open/WxAccountLive/del',
    method: 'post',
    data: { id }
  })
}
