<template>
  <div class="list-page">
    <reminder text="若小程序与视频号的主体相同或为关联主体，可以跳转到视频号直播间或在小程序内发起视频号直播预约"></reminder>
    <el-button class="top-btn" type="primary" size="small" @click="handleAddVideo">添加直播
    </el-button>
    <list-filter :filterForm="filterForm" :page="pageData.current_page" :uploadFilter="ok" />
    <div class="table-tab"></div>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button type="danger" size="small" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <el-checkbox v-if="['is_show','is_recommend'].includes(th.prop)" v-model="scope.row[th.prop]"
                :true-label="1" :false-label="0" @change="changeStatus(scope.row, th.prop)">
              </el-checkbox>
              <!-- 图片 -->
              <list-image v-else-if="th.prop === 'cover'" :src="scope.row.cover" fit="cover" is-image></list-image>
              <!-- 排序 -->
              <PopoverInputEdit v-else-if="th.prop === 'sort'" v-model="scope.row.sort" input-type="number"
                :validator="validator" @confirm="changeStatus(scope.row, th.prop)">
              </PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
        <!-- 操作 -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="editRow(scope.row)" type="text">编辑</el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <add-live v-model="addDialog.show" :form="addDialog.data" @refresh="refreshList"></add-live>
  </div>
</template>

<script>
import listFilter from "../components/live-list/ListFilter";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import {
  fetchLiveList, updateLiveSort, removeLive,
} from "../api/live-list";
import Reminder from "@/modules/common/components/Reminder";
import AddLive from "@/modules/wx-channels/components/live-list/AddLive";
import ListImage from "@/base/components/List/ListImage";
export default {
  data() {
    return {
      loading: false, // 加载
      thead: [
        { label: "排序", prop: "sort", minWidth: 100 },
        { label: "直播封面", prop: "cover", minWidth: 100 },
        {
          label: "直播主题",
          prop: "theme",
          minWidth: 110,
        },
        {
          label: "直播时间",
          prop: "live_time",
          minWidth: 330
        },
        {
          label: "分类",
          prop: "category_name",
          minWidth: 100,
        },
        {
          label: "浏览量",
          prop: "view_count",
          minWidth: 100,
          // sortable: true,
        },
        {label: "视频号ID", prop: "account_id"},
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          // sortable: true,
        },
      ],
      // 筛选对象
      filterForm: {
        create_start_time: -1, // 开始时间
        create_end_time: -1, // 结束时间
        live_start_time: -1,
        live_end_time: -1,
        account_id: "",
        category_id: -1, // 分类id
        theme: "",
        page_size: 15, // 每页多少条数据
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      selectArr: [], // 勾选的数组id

      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },

      addDialog: {
        show: false,
        data: {}
      }
    };
  },
  methods: {
    // 获取列表数据
    getList(pageData) {
      this.pageData = {};
      this.loading = true;
      fetchLiveList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增
    handleAddVideo() {
      this.addDialog.data = {id: 0, cover: '', theme: '', start_time: 0, end_time: 0}
      this.addDialog.show = true
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete(this.selectArr);
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据永久删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete(row.id);
        })
        .catch((err) => {});
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      removeLive(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.addDialog.data = row
      this.addDialog.show = true
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      this.loading = true;
      // 更新排序状态
      updateLiveSort(e.id, e[key])
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        }).catch(() => {
        });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getList(pageData);
    },
  },
  created() {
    this.getList(this.pageData); //获取列表数据
  },
  components: {
    ListImage,
    AddLive,
    Reminder,
    listFilter,
    Pagination,
    PopoverInputEdit,
  },
};
</script>

<style lang="scss" scoped>
.list-image {
  width: 60px;
  height: 60px;
}
</style>
